import { mdiArrowDownThick } from '@mdi/js'
import { useTheme } from 'styled-components'

import {
  WrapperHeader,
  WrapperImage,
  WrapperTitle,
  Background,
  Title,
  HeaderIcon
} from './styles'

export default function Header() {
  const { colors } = useTheme()
  return (
    <WrapperHeader>
      <Background />
      <WrapperImage>
        <img src='/assets/header.png' alt='truck-img' />
      </WrapperImage>
      <WrapperTitle>
        <div>
          <Title>
            Encuentra el <span>flete</span> que necesitas
          </Title>
          <p>
            Conectamos clientes con transportistas, solicita un viaje ó realiza un viaje.
          </p>
        </div>
      </WrapperTitle>
      <a href='#client'>
        <HeaderIcon path={mdiArrowDownThick} color={colors.gray6} />
      </a>
    </WrapperHeader>
  )
}
