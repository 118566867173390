import styled from 'styled-components'

export const WrapperFooter = styled.footer`
  width: 100%;
`
export const FooterContainer = styled.div`
  padding: 3rem 1rem;
  background: ${({ theme }) => theme.colors.purple1};
`

export const FooterList = styled.ul`
  width: 100%;
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.purple1};
  ${props => props.theme.device('md')} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ${props => props.theme.device('lg')} {
    max-width: 670px;
  }
`

export const FooterItem = styled.li`
  cursor: pointer;
  list-style: none;
  & > a {
    text-decoration: none;
    display: block;
    padding: 2rem 1rem;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.gray6};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }
  &:hover {
    background: ${({ theme }) => theme.colors.purple1};
    opacity: 0.8;
  }
`

export const FooterTerms = styled.div`
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  margin: 0 auto;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.colors.gray6};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  ${props => props.theme.device('md')} {
    max-width: 430px;
  }
  ${props => props.theme.device('lg')} {
    font-size: 16px;
  }
`
export const FooterIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  margin: 0 auto;
  & a > img {
    width: 32px;
    height: 32px;
  }
  ${props => props.theme.device('lg')} {
    max-width: 450px;
  }
`
