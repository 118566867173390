import styled from 'styled-components'

export const WrapperQuestions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${props => props.theme.device('md')} {
    flex-direction: row;
    align-items: flex-start;
    margin-top: 60px;
    margin-bottom: 20px;
  }
  ${props => props.theme.device('xxl')} {
    margin-top: 180px;
  }
`

export const WrapperAddQuestion = styled.div`
  margin-top: 4rem;
  margin-bottom: 2rem;
  & > h3 {
    font-size: 18px;
    margin-bottom: 24px;
    color: ${({ theme }) => theme.colors.gray1};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }
  & > p {
    font-size: 12px;
    margin-bottom: 30px;
    line-height: 24px;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: ${({ theme }) => theme.colors.gray3};
  }
  & > h5 {
    font-size: 12px;
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    color: ${({ theme }) => theme.colors.gray1};
    margin-bottom: 20px;
  }
  ${props => props.theme.device('md')} {
    width: 50%;
    margin-right: 2rem;
    margin-top: 0;
    margin-bottom: 0;
    & > h3 {
      font-size: 22px;
    }
    & > p {
      font-size: 14px;
    }
  }
  ${props => props.theme.device('lg')} {
    margin-right: 60px;
    & > h3 {
      font-size: 24px;
    }
    & > p {
      font-size: 16px;
    }
  }
  ${props => props.theme.device('xxl')} {
    max-width: 280px;
    margin-right: 140px;
  }
`

export const WrapperDropQuestions = styled.div`
  width: 100%;
  margin-bottom: 30px;
  & div > p {
    line-height: 24px;
    font-size: 12px;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: ${({ theme }) => theme.colors.gray3};
  }
  ${props => props.theme.device('lg')} {
    & div > p {
      font-size: 16px;
    }
  }
`

export const WrapperImage = styled.div`
  width: 130px;
  margin-bottom: -5px;
  & > img {
    width: 100%;
  }
`
