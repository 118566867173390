import styled, { css } from 'styled-components'
import { ButtonProps } from '../button'

export const baseButton = styled.button<Partial<ButtonProps>>`
  background: linear-gradient(153.24deg, #a40899 -116.8%, #5b0570 98.65%);
  box-shadow: 0px 4px 4px rgba(61, 4, 75, 0.11);
  border-radius: 38px;
  border: none;
  height: 48px;
  padding: 0px 20px;
  cursor: pointer;
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  transition: all 0.2s ease-in-out;
  color: ${({ theme }) => theme.colors.gray6};
  width: ${({ width }) => width || 'auto'};
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  &:hover {
    opacity: 0.8;
  }
  ${props =>
    props.icon &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      & > svg {
        margin-left: 20px;
      }
    `}
`

export const WrapperButton = styled(baseButton)`
  ${props =>
    props.variant === 'primary' &&
    css`
      background: ${props.theme.colors.purple1};
      :hover: {
        opacity: 0.8;
        background: ${props.theme.colors.blue3};
      }
    `}
  ${props =>
    props.variant === 'secondary' &&
    css`
      background: ${props.theme.colors.gray6};
      color: ${props.theme.colors.purple1};
      :hover: {
        opacity: 0.8;
        background: ${props.theme.colors.gray6};
      }
    `}
`
