import styled from 'styled-components'

const WrapperContainer = styled.div`
  max-width: 1440px;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 0 auto;
  height: 100%;
  ${props => props.theme.device('lg')} {
    padding: 0 50px;
  }
  ${props => props.theme.device('xxl')} {
    padding: 0 135px;
  }
`

export default function Container({
  children,
  id
}: {
  children: React.ReactNode
  id: string
}) {
  return <WrapperContainer id={id}>{children}</WrapperContainer>
}
