import styled from 'styled-components'

export const WrapperBudget = styled.div`
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${props => props.theme.device('md')} {
    flex-direction: row;
    justify-content: space-between;
  }
  ${props => props.theme.device('xxl')} {
    max-width: 1440px;
  }
`

export const WrapperImage = styled.figure`
  width: 100%;
  max-width: 354px;
  & > img {
    width: 100%;
  }
  ${props => props.theme.device('md')} {
    width: 50%;
    margin-right: 2rem;
  }
`

export const WrapperInfoBudget = styled.div`
  width: 100%;
  & > span {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.black1};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
  & > h2 {
    font-size: 28px;
    margin-top: 1rem;
    color: ${({ theme }) => theme.colors.black3};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }
  ${props => props.theme.device('md')} {
    & > span {
      font-size: 16px;
    }
    & > h2 {
      font-size: 36px;
    }
  }
  ${props => props.theme.device('lg')} {
    & > h2 {
      font-size: 56px;
    }
  }
  ${props => props.theme.device('xxl')} {
    max-width: 650px;
  }
`

export const InfoBudget = styled.div`
  margin-top: 3rem;
  ${props => props.theme.device('md')} {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
`

export const InfoBudgetDescription = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
  ${props => props.theme.device('md')} {
    flex-direction: column;
    width: 100%;
    align-items: baseline;
    max-width: 180px;
    &.right {
      margin: 0 30px;
    }
  }
`

export const BugetIcon = styled.div`
  width: 76px;
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 1rem;
  background: ${({ theme }) => theme.colors.gray4};
  & > svg {
    width: 2.7rem !important;
    height: 2.7rem !important;
  }
  ${props => props.theme.device('md')} {
    width: 50px;
    height: 50px;
    & > svg {
      width: 1.5rem !important;
      height: 1.5rem !important;
    }
  }
`

export const Description = styled.div`
  width: 70%;
  & > p {
    font-size: 16px;
    margin-bottom: 0.2rem;
    color: ${({ theme }) => theme.colors.gray1};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
  & > span {
    font-size: 12px;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: ${({ theme }) => theme.colors.gray3};
  }
  ${props => props.theme.device('md')} {
    width: 100%;
    margin-top: 1rem;
  }
`
