import { Routes, Route } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { theme } from './css/theme'
import Application from './app'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path='/' element={<Application />} />
      </Routes>
    </ThemeProvider>
  )
}

export default App
