import { mdiWhatsapp } from '@mdi/js'
import { useTheme } from 'styled-components'

import Container from '../../components/container'
import Button from '../../components/button'
import Dropdown from '../../components/dropdown'

import {
  WrapperQuestions,
  WrapperAddQuestion,
  WrapperDropQuestions,
  WrapperImage
} from './styles'

const questions = [
  {
    id: 0,
    question: '¿Cuál es el transporte adecuado para mi carga?',
    label: 'La app de infast cuenta con un amplio catálogo de transportes, donde podrás seleccionar por capacidad, peso y características que necesite lo que vas a transportar.'
  },
  {
    id: 1,
    question: '¿Cómo funcionan las ofertas?',
    label: 'Con la APP de infast los transportistas enviarán  ofertas respecto a tu viaje y presupuesto así podrás elegir la mejor oferta'
  },
  {
    id: 2,
    question: '¿Cómo es el proceso de pago?',
    label: 'Podrás hacer el pago en ventanilla o desde tu banca electrónica a la cuenta de infast, y deberás subir tu comprobante en la app de infast, nuestro equipo validará tu pago. El pago se dividirá en 2 partes se pedirá 50% de anticipo para iniciar tu viaje y 50% al finalizar tu viaje.'
  }
]

export default function Questions() {
  const { colors } = useTheme()
  return (
    <Container id='questions'>
      <WrapperQuestions>
        <WrapperAddQuestion>
          <h3>¿Tienes dudas?</h3>
          <Button
            width='100%'
            maxWidth='260px'
            variant='secondary'
            icon={mdiWhatsapp}
            iconColor={colors.green1}
          >
            Escribenos
          </Button>
        </WrapperAddQuestion>
        <WrapperDropQuestions>
          {questions.map(({ id, question, label }) => (
            <Dropdown key={id} title={question}>
              <p>{label}</p>
            </Dropdown>
          ))}
        </WrapperDropQuestions>
      </WrapperQuestions>
      <WrapperImage>
        <img src='/assets/truck.png' alt='truck-img' />
      </WrapperImage>
    </Container>
  )
}
