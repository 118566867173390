import React from 'react'
import Icon from '../../components/icon'
import { WrapperButton } from './styles'

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
  width?: string
  maxWidth?: string
  variant?: 'primary' | 'secondary'
  icon?: string
  iconColor?: string | null
}

export default function Button({
  children,
  width,
  icon,
  iconColor,
  variant = 'primary',
  maxWidth,
  ...props
}: ButtonProps) {
  return (
    <WrapperButton
      width={width}
      maxWidth={maxWidth}
      variant={variant}
      icon={icon}
      {...props}
    >
      {children}
      {icon && <Icon path={icon} color={iconColor} />}
    </WrapperButton>
  )
}
