import Layout from '../components/layout'
import Budget from './budget'
import Header from './header'
import Routes from './routes'
import Questions from './questions'
import { GlobalStyle } from '../css/theme'

export default function App() {
  return (
    <Layout>
      <GlobalStyle />
      <Header />
      <Budget />
      <Routes />
      <Questions />
    </Layout>
  )
}
