import MdiIcon from '@mdi/react'
import { useTheme } from 'styled-components'

type Icons = React.ComponentProps<typeof MdiIcon>

interface IconsProps extends Icons {
  onClick?: () => void
}
export default function Icon({ ...props }: IconsProps) {
  const { colors } = useTheme()
  return <MdiIcon title='icon' size={1} color={colors.gray2} {...props} />
}
