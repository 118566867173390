export const primary = {
  blue1: '#2D3662',
  blue2: '#2F4194',
  blue3: '#5373E9',
  blue4: '#98AAF2',
  blue5: '#CFD8FF',
  blue6: '#F0F3FF'
}

export const secondary = {
  aqua1: '#3399A2',
  aqua2: '#37C2CF',
  aqua3: '#8DF0F7',
  aqua4: '#CEFCFF',
  aqua5: '#E5FDFF',
  aqua6: '#F2FEFF'
}

export const tertiary = {
  purple1: '#5B0570',
  purple2: '#7060B2',
  purple3: '#9D90D1',
  purple4: '#D2C8E6',
  purple5: '#ECE6F6',
  purple6: '#F7F3FF'
}

export const grayscale = {
  gray1: '#2E2839',
  gray2: '#52515A',
  gray3: '#999999',
  gray4: '#F6F6F9',
  gray5: '#eeeeee',
  gray6: '#FFFFFF'
}

export const alert = {
  red1: '#FA648D',
  red2: '#FF8E91',
  red3: '#FFECF3',
  red4: '#FFEEF0',
  yellow1: '#F6D44B',
  yellow2: '#FFE67F',
  yellow3: '#FFFCEE',
  orange1: '#FFA400',
  orange2: '#FFF7EE',
  orange3: '#FFF4E7',
  green1: '#20B038',
  green2: '#7FEFC3',
  green3: '#E9FFF8'
}
export const text = {
  black1: '#000000',
  black2: '#111111',
  black3: '#333333'
}
