import { useState, useEffect } from 'react'
import { mdiPlus } from '@mdi/js'

import Icon from '../icon'
import { WrapperTitle, WrapperDropdown, Children } from './styles'

export default function Dropdown({
  children,
  title,
  openDefault
}: {
  children: React.ReactNode
  title: string
  openDefault?: boolean
}) {
  const [isOpen, setIsOpen] = useState<boolean | undefined>()
  useEffect(() => {
    if (openDefault) {
      setIsOpen(openDefault)
    }
  }, [openDefault])

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <WrapperDropdown>
      <WrapperTitle role='button' onClick={handleToggle}>
        <h3>{title}</h3>
        <Icon path={mdiPlus} size={0.7} />
      </WrapperTitle>
      {isOpen && <Children>{children}</Children>}
    </WrapperDropdown>
  )
}
