import { createGlobalStyle, DefaultTheme } from 'styled-components'
import { primary, secondary, tertiary, grayscale, alert, text } from './colors'

type Breakpoint = 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
type Sizes = '576px' | '767px' | '992px' | '1200px' | '1440px'

export const fontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700
}

export const hexToRGB = (hex: string, alpha: string): string => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  } else {
    return `rgb(${r}, ${g}, ${b})`
  }
}

const sizes: { [key in Breakpoint]: Sizes } = {
  sm: '576px',
  md: '767px',
  lg: '992px',
  xl: '1200px',
  xxl: '1440px'
}

export const device = (size: 'sm' | 'md' | 'lg' | 'xl' | 'xxl'): string => {
  const mediaRegex = /(d*\.?\d+)(px|em|%|rem|vw|pt+)/g.test(size)
  const breakpoint = Object.keys(sizes).includes(size)

  if (!mediaRegex && !breakpoint) {
    console.error(`Error: Media query ${size} not supported`)
  }

  const media = Object.keys(sizes).includes(size) ? sizes[size] : size
  return `@media (min-width: ${media})`
}

export const theme: DefaultTheme = {
  hexToRGB,
  device,
  fontWeight: { ...fontWeight },
  colors: {
    ...primary,
    ...secondary,
    ...tertiary,
    ...grayscale,
    ...alert,
    ...text
  }
}

export const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Rubik', sans-serif;
    background-color: ${theme.colors.gray6};
  }
  h1, h2, h3, h4, h5, h6, p, ul, li, figure {
    margin: 0;
    padding: 0;
  }
  img {
    width: 100px;
  }
`
