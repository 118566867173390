import styled, { css } from 'styled-components'

export const WrapperLayout = styled.div`
  width: 100%;
`
export const WrapperNabvar = styled.div`
  background: ${props => props.theme.colors.gray6};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  ${props => props.theme.device('md')} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
  }
`
export const WrapperLogo = styled.div`
  padding: 0 0.5rem;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  & > figure {
    margin-right: calc(50% - 90px);
    width: 140px;
    margin: 0 auto;
  }
  & img {
    width: 100%;
  }
  ${props => props.theme.device('md')} {
    height: 80px;
    padding: 0;
    margin-right: 10px;
    & > figure {
      width: 180px;
    }
    & > svg {
      display: none;
    }
  }
`
export const Navbar = styled.nav<{ openMenu: boolean }>`
  list-style: none;
  max-width: 1440px;
  position: fixed;
  width: 100%;
  top: 60px;
  bottom: 0;
  left: -100%;
  transition: left 200ms linear;
  box-shadow: 0px 16px 58px
    ${props => props.theme.hexToRGB(props.theme.colors.black1, '0.08')};
  ${props =>
    props.openMenu &&
    css`
      left: 0;
      transition: left 200ms linear;
    `}
  ${props => props.theme.device('md')} {
    position: initial;
    box-shadow: none;
    height: 80px;
  }
`
export const UnOrderedList = styled.ul`
  list-style: none;
  width: 70%;
  height: 100%;
  background: ${props => props.theme.colors.gray6};
  box-shadow: 0px 100px 70px
    ${props => props.theme.hexToRGB(props.theme.colors.black1, '0.5')};
  ${props => props.theme.device('md')} {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-shadow: none;
    width: 100%;
    grid-gap: 1rem;
  }
  ${props => props.theme.device('lg')} {
    grid-gap: 2.5rem;
  }
`

export const ListItem = styled.li<{ classButton: string }>`
  cursor: pointer;
  position: relative;
  ${props => props.theme.device('md')} {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      ::before {
        content: '';
        width: 100%;
        height: 6px;
        position: absolute;
        bottom: 0;
        border-radius: 10px 10px 0 0;
        background: ${({ theme, classButton }) =>
          classButton ? '' : theme.colors.purple1};
      }
    }
  }
  ${props => props.theme.device('lg')} {
    width: ${props => (props.classButton ? '160px' : 'auto')};
  }
  & > a {
    text-decoration: none;
    display: block;
    padding: 2rem 1rem;
    font-size: 16px;
    color: ${props => props.theme.colors.black3};
    ${props => props.theme.device('md')} {
      padding: 0;
      background: ${props => props.theme.colors.gray6};
    }
  }
  .classButton {
    background: linear-gradient(153.24deg, #a40899 -116.8%, #5b0570 98.65%);
    color: ${props => props.theme.colors.gray6};
    padding: 1rem 1rem;
    margin-left: 1rem;
    margin-top: 1.5rem;
    width: 70%;
    text-align: center;
    border-radius: 38px;
    font-weight: ${props => props.theme.fontWeight.bold};
    ${props => props.theme.device('md')} {
      margin-top: 0;
    }
    ${props => props.theme.device('lg')} {
      width: 100%;
    }
  }
  &:hover {
    background: ${({ theme, classButton }) =>
      classButton ? '' : theme.colors.gray5};
    ${props => props.theme.device('md')} {
      background: transparent;
    }
  }
`

export const LayoutContent = styled.div`
  ${props => props.theme.device('md')} {
    display: flex;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
  }
  ${props => props.theme.device('lg')} {
    padding: 0 40px;
  }
  ${props => props.theme.device('xxl')} {
    padding: 0 135px;
    box-sizing: border-box;
  }
`
