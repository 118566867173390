import styled from 'styled-components'
import Icon from '../../components/icon'

export const WrapperHeader = styled.header`
  margin-top: 60px;
  position: relative;
  height: calc(100vh - 60px);
  ${props => props.theme.device('md')} {
    margin-top: 80px;
  }
`

export const WrapperImage = styled.figure`
  width: 100%;
  height: 100%;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
export const WrapperTitle = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: absolute;
  top: 0;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  box-sizing: border-box;
  align-items: flex-start;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  & p {
    color: ${props => props.theme.colors.gray6};
    font-size: 16px;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    ${props => props.theme.device('md')} {
      font-size: 20px;
    }
  }
  ${props => props.theme.device('md')} {
    & > div {
      height: calc(100vh - 80px);
      width: 80%;
    }
  }
  ${props => props.theme.device('lg')} {
    padding: 0 50px;
  }
  ${props => props.theme.device('xxl')} {
    padding: 0 135px;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
  }
`

export const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 30px;
  color: ${props => props.theme.colors.gray6};
  font-weight: ${props => props.theme.fontWeight.regular};
  & > span {
    color: ${props => props.theme.colors.orange1};
  }
  ${props => props.theme.device('md')} {
    font-size: 55px;
    line-height: 80px;
  }
  ${props => props.theme.device('lg')} {
    font-size: 72px;
  }
`

export const Background = styled.div`
  top: 0;
  width: 100%;
  height: calc(100vh - 60px);
  position: absolute;
  background: ${props =>
    props.theme.hexToRGB(props.theme.colors.black1, '0.5')};
`

export const HeaderIcon = styled(Icon)`
  bottom: 40px;
  position: absolute;
  left: 20px;
`
