import { mdiMessageText, mdiBusClock, mdiTruck } from '@mdi/js'

import Container from '../../components/container'
import Icon from '../../components/icon'
import {
  WrapperBudget,
  WrapperImage,
  InfoBudget,
  WrapperInfoBudget,
  InfoBudgetDescription,
  Description,
  BugetIcon
} from './styles'

export default function Budget() {
  return (
    <Container id='client'>
      <WrapperBudget>
        <WrapperImage>
          <img src='/assets/budget.png' alt='budget-img' />
        </WrapperImage>
        <WrapperInfoBudget>
          <span>¿Necesitas un viaje para tus productos?</span>
          <h2>Transporte adaptado a tu presupuesto</h2>
          <InfoBudget>
            <InfoBudgetDescription>
              <BugetIcon>
                <Icon path={mdiMessageText} />
              </BugetIcon>
              <Description>
                <p>Solicita</p>
                <span>
                  Genera tu solicitud de viaje y ofrece el precio que puedes
                  pagar
                </span>
              </Description>
            </InfoBudgetDescription>
            <InfoBudgetDescription className='right'>
              <BugetIcon>
                <Icon path={mdiBusClock} />
              </BugetIcon>
              <Description>
                <p>Recibe ofertas</p>
                <span>Espera 24hrs a la oferta más baja</span>
              </Description>
            </InfoBudgetDescription>
            <InfoBudgetDescription>
              <BugetIcon>
                <Icon path={mdiTruck} />
              </BugetIcon>
              <Description>
                <p>Comieza</p>
                <span>Inicia tu viaje</span>
              </Description>
            </InfoBudgetDescription>
          </InfoBudget>
        </WrapperInfoBudget>
      </WrapperBudget>
    </Container>
  )
}
