import styled from 'styled-components'

export const WrapperDropdown = styled.div`
  width: 100%;
  padding: 0 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.gray5};
`

export const WrapperTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  cursor: pointer;
  & > h3 {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.gray1};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
  ${props => props.theme.device('lg')} {
    font-size: 20px;
  }
`

export const Children = styled.div`
  padding-bottom: 16px;
`
