import { mdiBell, mdiMagnify, mdiBusClock } from '@mdi/js'

import Container from '../../components/container'
import Icon from '../../components/icon'
import {
  WrapperRoutes,
  WrapperImage,
  InfoRoutes,
  WrapperInfoRoutes,
  InfoRoutesDescription,
  Description,
  RoutesIcon
} from './styles'

export default function Routes() {
  return (
    <Container id='driver'>
      <WrapperRoutes>
        <WrapperImage>
          <img src='/assets/routes.png' alt='budget-img' />
        </WrapperImage>
        <WrapperInfoRoutes>
          <span>¿Quieres aprovechar viajes con tu transporte?</span>
          <h2>Aprovecha tus rutas de transporte</h2>
          <InfoRoutes>
            <InfoRoutesDescription>
              <RoutesIcon>
                <Icon path={mdiBell} />
              </RoutesIcon>
              <Description>
                <p>Obtén ofertas</p>
                <span>Recibe ofertas de transporte</span>
              </Description>
            </InfoRoutesDescription>
            <InfoRoutesDescription className='right'>
              <RoutesIcon>
                <Icon path={mdiMagnify} />
              </RoutesIcon>
              <Description>
                <p>Genera presupuesto</p>
                <span>Participa ofreciendo precios rentables</span>
              </Description>
            </InfoRoutesDescription>
            <InfoRoutesDescription>
              <RoutesIcon>
                <Icon path={mdiBusClock} />
              </RoutesIcon>
              <Description>
                <p>Transporta</p>
                <span>Inicia tu viaje</span>
              </Description>
            </InfoRoutesDescription>
          </InfoRoutes>
        </WrapperInfoRoutes>
      </WrapperRoutes>
    </Container>
  )
}
