import React, { useState } from 'react'
import { mdiMenu } from '@mdi/js'

import Footer from '../../app/footer'
import Icon from '../icon'
import {
  WrapperLayout,
  WrapperNabvar,
  WrapperLogo,
  Navbar,
  UnOrderedList,
  ListItem,
  LayoutContent
} from './styles'

type Labels =
  | '¿Que hacemos?'
  | 'Clientes'
  | 'Transportistas'
  | 'Ingresar'
  | 'Regístrate'

type MenuItems = {
  id: number
  label: Labels
  route: string
}
const menuItems: MenuItems[] = [
  { id: 0, label: '¿Que hacemos?', route: '#about' },
  { id: 1, label: 'Clientes', route: '#client' },
  { id: 2, label: 'Transportistas', route: '#driver' },
  { id: 3, label: 'Ingresar', route: 'https://app.infast.com.mx/PublicStack/login' },
  { id: 4, label: 'Regístrate', route: 'https://app.infast.com.mx/user/signup' }
]

export default function Layout({ children }: { children: React.ReactNode }) {
  const [openMenu, setOpenMenu] = useState(false)

  const hanldeToggleMenu = () => {
    setOpenMenu(!openMenu)
  }

  return (
    <WrapperLayout>
      <WrapperNabvar>
        <LayoutContent>
          <WrapperLogo>
            <Icon onClick={hanldeToggleMenu} path={mdiMenu} size={1.5} />
            <figure>
              <img src='/assets/logo.png' alt='logo' />
            </figure>
          </WrapperLogo>
          <Navbar openMenu={openMenu}>
            <UnOrderedList>
              {menuItems.map(({ id, label, route }) => {
                const classButton =
                  id === 4 && label === 'Regístrate' ? 'classButton' : ''
                return (
                  <ListItem
                    key={id}
                    role='button'
                    tabIndex={0}
                    classButton={classButton}
                    onClick={hanldeToggleMenu}
                  >
                    <a className={classButton} href={route}>
                      {label}
                    </a>
                  </ListItem>
                )
              })}
            </UnOrderedList>
          </Navbar>
        </LayoutContent>
      </WrapperNabvar>
      {children}
      <Footer />
    </WrapperLayout>
  )
}
