import {
  WrapperFooter,
  FooterContainer,
  FooterItem,
  FooterList,
  FooterTerms,
  FooterIcons
} from './styles'

type Labels =
  | '¿Que hacemos?'
  | 'Clientes'
  | 'Transportistas'
  | 'Terminos & condiciones'
  | 'FAQS'
type Routes = '#about' | '#client' | '#driver' | '#terms' | '#questions'
type FooterItems = {
  id: number
  label: Labels
  route: Routes
}

const footerItems: FooterItems[] = [
  { id: 0, label: '¿Que hacemos?', route: '#about' },
  { id: 1, label: 'Clientes', route: '#client' },
  { id: 2, label: 'Transportistas', route: '#driver' },
  { id: 3, label: 'Terminos & condiciones', route: '#terms' },
  { id: 4, label: 'FAQS', route: '#questions' }
]

export default function Footer() {
  return (
    <WrapperFooter>
      <FooterContainer>
        <FooterList>
          {footerItems.map(({ id, label, route }) => (
            <FooterItem key={id}>
              <a href={route}>{label}</a>
            </FooterItem>
          ))}
        </FooterList>
        <FooterTerms>
          Términos y condiciones y Políticas de privacidad INFAST © Copyright
          2021. Todos los derechos reservados.
        </FooterTerms>
        <FooterIcons>
          <a href='/'>
            <img src='/assets/facebook.svg' alt='icon-facebook' />
          </a>
          <a href='/'>
            <img
              src='/assets/twitter.svg'
              alt='icon-twitter'
              className='twitter'
            />
          </a>
          <a href='/'>
            <img src='/assets/instagram.svg' alt='icon-instagram' />
          </a>
        </FooterIcons>
      </FooterContainer>
    </WrapperFooter>
  )
}
